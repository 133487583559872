/**
 * Skinning Pack, Loaded well before dom processing is completed
 * to setup Aztec Skinning System.
 */
import "core-js/stable";
import "regenerator-runtime/runtime";

import '../stylesheets/aztec_skin.scss';
const Medusa = {
    install: function (Vue) {
        Vue.prototype.$medusa = this;
        Vue.medusa = this;
    },
    ready: function() {
        let me = this;
        me.skin_sheets['kaplan'] = window.kaplan_node;
        delete window.kaplan_node;
        me.skin_sheets['gedts'] = window.gedts_node;
        delete window.gedts_node;
    },
    skin_sheets: {
        kaplan: null,
        gedts: null
    },
    active_skin: 'aztec',
    detach_skin: function(skin){
        let me = this;
        let node = document.getElementById(skin+'_skin_sheet');
        if(node) {
            let head_tag = document.getElementsByTagName('head')[0];
            if(head_tag) {
                head_tag.removeChild(node);
            }
            me.skin_sheets[skin] = node;
        }
    },
    attach_skin: function(skin){
        let me = this;
        if(me.skin_sheets[skin]) {
            let head_tag = document.getElementsByTagName('head')[0];
            if(head_tag) {
                head_tag.appendChild(me.skin_sheets[skin]);
            }
            me.skin_sheets[skin] = null;
        }
    },
    activateSkin: function(newSkin){
        let me = this;
        if(newSkin === 'kaplan' && me.active_skin !== 'kaplan') {
            me.detach_skin('gedts');
            me.attach_skin('kaplan');
            me.active_skin = 'kaplan';
        }
        if(newSkin === 'gedts' && me.active_skin !== 'gedts') {
            me.detach_skin('kaplan');
            me.attach_skin('gedts');
            me.active_skin = 'gedts';
        }
        if((newSkin === 'aztec' || newSkin === null || newSkin == '') && me.active_skin !== 'aztec') {
            me.detach_skin('gedts');
            me.detach_skin('kaplan');
            me.active_skin = 'aztec';
        }
        window.video_set = me.active_skin;
        if(window.site_logo.length > 3) {
            let node = document.getElementById('site_logo');
            let head_tag = document.getElementsByTagName('head')[0];
            if(head_tag && node) {
                head_tag.removeChild(node);
                head_tag.appendChild(node);
            }
        }
    }
};
window.Medusa = Medusa;
console.log("Skin support activated");